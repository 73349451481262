require('../css/news.scss');
var $ = require('jquery');

$(document).delegate(".more-news p","click",function(){

    event.preventDefault();
    var maxTime = $(this).attr('max-attr');
    that = $(this);
    $.post("/post/more",
        {
            maxTime:maxTime
        },
        function(data,status) {
            if(status){

                if(!data.html)
                {
                    that.html('没有更多资讯了');
                }
                that.attr('max-attr',data.maxTime);
                that.parent().parent('.row').before(data.html);
            }
        });
});


$(function() {
    if ($("#page.news.show").length > 0) {
        var url = window.location.href;
        var API_URLS ='http://qichedaquan.com';
        $.getJSON(API_URLS+'/weixin/share?url='+encodeURIComponent(url), function(json){
            console.log(json);
            var params = {
                debug: false,
                appId: json.appId,
                timestamp: json.timestamp,
                nonceStr: json.nonceStr,
                signature: json.signature,
                jsApiList: [
                    'onMenuShareTimeline',
                    'onMenuShareAppMessage',
                ]
            };

            wx.config(params);
            var title  = $(".news-content .title").html();
            var desc   = $(".post-summary").val();
            var link   = window.location.href;
            var imgUrl = $(".post-thumb").val();
            wx.ready(function(){
                //分享到朋友圈
                wx.onMenuShareTimeline({
                    title: title,
                    desc: desc,
                    link: link,
                    imgUrl: imgUrl
                });
                //分享给朋友
                wx.onMenuShareAppMessage({
                    title: title,
                    desc: desc,
                    link: link,
                    imgUrl: imgUrl
                });

            });
            wx.error(function(res){
                console.log(res);
            });
        });

    }
})

